import Banner from "./Banner";
import Info from "./Info";
import Statistic from "./Statistic";

const Testing = () => {
  return (
    <>
      <Banner />
      <Info />
      <Statistic />
    </>
  );
};

export default Testing;
